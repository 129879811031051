import React from 'react';
import NavBar from '../General/NavBar';
import CustomCard from '../General/CustomCard';
import { Grid } from '@material-ui/core';
import { AccountCircle, AccessAlarm } from '@material-ui/icons';


const Home = () => {

    return (
        <>
            <NavBar />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomCard icon={<AccountCircle />} title="Usuarios" path="/users" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CustomCard icon={<AccessAlarm />} title="Tests" path="/tests" />
                </Grid>
            </Grid>
        </>
    );
}
export default Home;