import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Box, Typography, Button } from '@mui/material';
import QuestionsForm from './QuestionsForm';
import test_services from '../Services/test_services';

// Datos de ejemplo
/*const tests = [
  {id:1, title: 'test1', description: 'primer tests', created: '121221' },
  {id:1, title: 'test2', description: 'segundo test', created: '121222' },
];*/

// Estilo para el modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  maxHeight: '70vh',
  overflow: 'scroll',
  boxShadow: 24,
  p: 4,
};

function TestsTable() {
  const [open, setOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState({
    id:"",
    title:"",
    description:"",
    created:"",
    questions:[]
  });
  const [testList, setTestList] = useState([]);
  const [addQuestion, setAddQuestion] = useState(false);

  const token = useSelector(state => state.auth.token);
  useEffect(() => {
    test_services.list(token).then((data)=>{
        setTestList(data);
    });
  },[token]);

  const handleOpen =async (test) => {
    let test_data = await test_services.info(token, test.id);
    setSelectedTest(test_data);
    setOpen(true);
  };

  const handleClose = () => {
    setAddQuestion(false);
    setOpen(false);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Creado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testList.map((test, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                onClick={() => handleOpen(test)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">
                  {test.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {test.title}
                </TableCell>
                <TableCell component="th" scope="row">
                  {test.description}
                </TableCell>
                <TableCell component="th" scope="row">
                  {test.created}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Test
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Id: {selectedTest.id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Title: {selectedTest.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Description: {selectedTest.description}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Created: {selectedTest.created}
          </Typography>
          {selectedTest.questions.map((question, index) => (
            <div key={index}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Id Pregunta: {question.id}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Pregunta: {question.question}
                </Typography>
                {question.question_options.map((question_option, optionIndex) => (
                <Typography id="modal-modal-description" sx={{ mt: 2 }} key={optionIndex}>
                    Id respuesta: {question_option.id} <br/>
                    {question_option.text}
                    {question_option.right_option && ("(R)")}
                </Typography>
                ))}
            </div>
          ))}
          {addQuestion && (
            <QuestionsForm testId={selectedTest.id}/>
          )}
          {!addQuestion && (
            <Button variant="contained" onClick={() => setAddQuestion(true)}>Agregar Preguntas</Button>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default TestsTable;
