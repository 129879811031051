import React from 'react';
import NavBar from '../General/NavBar';
import { Grid, Container } from '@material-ui/core';
import UserList from './UserList';
import AddUser from './AddUser';

const Users = () => {

    return (
        <>
            <NavBar />
            <Grid container spacing={3}>
                <Container>
                    <h1>Usuarios</h1>
                    <AddUser/>
                    <UserList/>
                </Container>
            </Grid>
        </>
    );
}
export default Users;