import React, { useState } from 'react';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';
import user_services from '../Services/user_services';

const AddUser = () => {

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [school, setSchool] = useState('');
    const [grade, setGrade] = useState('');
    const [disability, setDisability] = useState('');
    const [disabilityDescription, setDisabilityDescription] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        //let added_user = await user_services.add_user(email,password);
        let user_data = {
            email,
            password,
            name,
            "last_name": lastName,
            phone,
            school,
            grade,
            disability,
            "disability_description": disabilityDescription
        }
        let added_user = await user_services.add_user(user_data);
        if(!added_user){
            setError("Datos incorrectos");
        }else{
            setEmail('');
            setPassword('');
            handleClose();
            window.location.reload();
        }
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Agregar Usuario</Button>
            <br/><br/>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                maxWidth: 400,
                width: '100%',
                borderRadius: 4,
                }}>
                <Typography id="modal-title" variant="h5" gutterBottom>Agregar Usuario</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                    label="Correo"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Contraseña"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Nombre"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Apellidos"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Teléfono"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Escuela"
                    type="text"
                    value={school}
                    onChange={(e) => setSchool(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Grado"
                    type="text"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Discapacidad"
                    type="text"
                    value={disability}
                    onChange={(e) => setDisability(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <TextField
                    label="Detalle de la discapacidad"
                    type="text"
                    value={disabilityDescription}
                    onChange={(e) => setDisabilityDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                    />
                    <Button type="submit" variant="contained" color="primary">Agregar</Button>
                </form>
                {error && (
                    <Typography color="error" align="center">
                    {error}
                    </Typography>
                )}
                </Box>
            </Modal>
        </>
    );
}
export default AddUser;