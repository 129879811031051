import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, IconButton, Grid, Container } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import test_services from '../Services/test_services';
import { useSelector } from 'react-redux';

function QuestionsForm(testInfo) {

    //console.log("el test id" + JSON.stringify(testInfo.testId));

    const [questions, setQuestions] = useState([{ questionText: '', options: [{ optionText: '', isCorrect: false }] }]);

    const token = useSelector(state => state.auth.token);

    const handleAddQuestion = () => {
      setQuestions([...questions, { questionText: '', options: [{ optionText: '', isCorrect: false }] }]);
    };
  
    const handleRemoveQuestion = (index) => {
      const newQuestions = [...questions];
      newQuestions.splice(index, 1);
      setQuestions(newQuestions);
    };

    const handleAddOption = (questionIndex) => {
      const newQuestions = [...questions];
      newQuestions[questionIndex].options.push({ optionText: '', isCorrect: false });
      setQuestions(newQuestions);
    };
  
    const handleRemoveOption = (questionIndex, optionIndex) => {
      const newQuestions = [...questions];
      newQuestions[questionIndex].options.splice(optionIndex, 1);
      setQuestions(newQuestions);
    };
  
    const handleChangeQuestion = (e, questionIndex) => {
      const { value } = e.target;
      const newQuestions = [...questions];
      newQuestions[questionIndex].questionText = value;
      setQuestions(newQuestions);
    };
  
    const handleChangeOptionText = (e, questionIndex, optionIndex) => {
      const { value } = e.target;
      const newQuestions = [...questions];
      newQuestions[questionIndex].options[optionIndex].optionText = value;
      setQuestions(newQuestions);
    };
  
    const handleChangeOptionCorrect = (e, questionIndex, optionIndex) => {
      const { checked } = e.target;
      const newQuestions = [...questions];
      newQuestions[questionIndex].options[optionIndex].isCorrect = checked;
      setQuestions(newQuestions);
    };

    const handleSave = async () => {
        for (let i = 0; i < questions.length; i++) {
            const savedQuestion = await test_services.save_question(token, testInfo.testId, questions[i].questionText);
            //console.log("Pregunta guardada:", savedQuestion);

            const options = questions[i].options.map(option => ({
                text: option.optionText,
                right_option: option.isCorrect
            }));
            const savedOptions = await test_services.save_options(token, savedQuestion.id, options);
            //console.log("Opciones guardadas:", savedOptions);
        }
        alert("Preguntas guardadas");
        window.location.reload();
    };


    return (
    <>
        <Container>
            <br/><br/><br/>
            {questions.map((question, questionIndex) => (
                <Grid container spacing={2} key={questionIndex}>
                    <Grid container key={questionIndex} style={{marginBottom:"20px"}}>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label={`Question ${questionIndex + 1}`}
                                value={question.questionText}
                                onChange={(e) => handleChangeQuestion(e, questionIndex)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid container xs={2}>
                            <IconButton aria-label="delete" onClick={() => handleRemoveQuestion(questionIndex)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {question.options.map((option, optionIndex) => (
                        <Grid container key={optionIndex}>
                            <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    label={`Option ${optionIndex + 1}`}
                                    value={option.optionText}
                                    onChange={(e) => handleChangeOptionText(e, questionIndex, optionIndex)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="delete" onClick={() => handleRemoveOption(questionIndex, optionIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={<Checkbox checked={option.isCorrect} onChange={(e) => handleChangeOptionCorrect(e, questionIndex, optionIndex)} />}
                                    label="Respuesta Correcta"
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12} style={{marginBottom:"30px"}}>
                        <Button variant="contained" onClick={() => handleAddOption(questionIndex)}>Agregar Respuesta</Button>
                    </Grid>
                </Grid>
            ))}
            <Button variant="contained" onClick={handleAddQuestion}>Agregar otra pregunta</Button>
            <Button variant="contained" color="primary" onClick={handleSave}>Guardar</Button>
        </Container>
        <br/>
    </>
    );
}

export default QuestionsForm;
