import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Box, Typography } from '@mui/material';
import user_services from '../Services/user_services';

// Datos de ejemplo
/*
const users = [
  {id:1, email: 'usuario1@example.com' },
  {id:2, email: 'usuario2@example.com' }
];
*/

// Estilo para el modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function UserTable() {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userList, setUserList] = useState([]);

  const token = useSelector(state => state.auth.token);
  useEffect(() => {
    user_services.list(token).then((data)=>{
        setUserList(data);
    });
  },[token]);

  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Email del Usuario</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                onClick={() => handleOpen(user)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">
                  {user.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {user.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Información del Usuario
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Id: {selectedUser.id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Email: {selectedUser.email}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Nivel: {selectedUser.current_level}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Nombre: {selectedUser.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Apellidos: {selectedUser.last_name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Teléfono: {selectedUser.phone}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Escuela: {selectedUser.school}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Grado: {selectedUser.grade}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            discapacidad: {selectedUser.disability}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Detalle discapacidad: {selectedUser.disability_description}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default UserTable;
