import React, { useState } from 'react';
import NavBar from '../General/NavBar';
import { Grid, Container} from '@material-ui/core';
import { Modal, Box, Typography } from '@mui/material';
import { Button } from '@mui/material';
import TestsList from './TestsList';
import CreateTest from './CreateTest';

// Estilo para el modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Tests = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <NavBar />
            <Grid container spacing={3}>
                <Container>
                    <h1>Tests</h1>
                    <Button onClick={() => handleOpen()} variant="contained">Crear Test</Button>
                    <br/><br/>
                    <TestsList/>
                </Container>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CreateTest/>
                </Box>
            </Modal>
        </>
    );
}
export default Tests;