import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import test_services from '../Services/test_services';

const CreateTestForm = () => {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    
    const token = useSelector(state => state.auth.token);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let create_test = await test_services.create(token, title, description);
        if(!create_test){
            setError("Datos incorrectos");
        }else{
            setTitle('');
            setDescription('');
            window.location.reload();
        }
    };

    return (
        <> 
            <h1>Nuevo Test</h1>
            <Grid container spacing={3}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Título"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Descripción"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <Button type="submit" variant="contained" color="primary">Crear</Button>
                </form>
                {error && (
                    <Typography color="error" align="center">
                        {error}
                    </Typography>
                )} 
            </Grid>
        </>
    );
}
export default CreateTestForm;