import axios from 'axios';
import url from './url';

let test_services = {
    list: async (token) => {
        try {
            const response = await axios.get(url+'/test/list_tests', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res)=>{
                let data = res.data;
                if(data.status == "success"){
                    return data.data;
                }else{
                    return false;
                }
            });

            return response;

        } catch (error) {
            console.error('Error al obtener los tests:', error);
        }
    },
    info: async (token, id) => {
        try {
            const response = await axios.get(url+'/test/test_info/'+id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res)=>{
                let data = res.data;
                if(data.status == "success"){
                    return data.data;
                }else{
                    return false;
                }
            });

            return response;

        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    },
    create: async (token, title, description) => {
        try {
            const response = await axios.post(url+'/test/create_test', {
                title:title,
                description:description
            },{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res)=>{
                let data = res.data;
                if(data.status == "success"){
                    return data.data;
                }else{
                    return false;
                }
            });

            return response;

        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    },
    save_question: async (token, test_id, question) => {
        try {
            const response = await axios.post(url+'/test/add_test_question', {
                test_id:test_id,
                question:question
            },{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res)=>{
                let data = res.data;
                if(data.status == "success"){
                    return data.data;
                }else{
                    return false;
                }
            });

            return response;

        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    },
    save_options: async (token, question_id, options) => {
        try {
            const response = await axios.post(url+'/test/add_question_options', {
                question_id:question_id,
                options:JSON.stringify(options)
            },{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((res)=>{
                let data = res.data;
                if(data.status == "success"){
                    return data.data;
                }else{
                    return false;
                }
            });

            return response;

        } catch (error) {
            console.error('Error al obtener datos:', error);
        }
    },    
}

export default test_services;