import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: 10,
    cursor: "pointer"
  },
  title: {
    fontSize: 14,
  },
});

const CustomCard = ({ icon, title, path }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(path)} className={classes.card}>
      <CardContent>
        <Icon>{icon}</Icon>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
